import React from 'react';
import './App.css';
import styled from 'styled-components';
import img_logo from './img/logo.svg';
import img_background from './img/background.jpg';

export type AppProps = {
    className?: string;
    as?: any;
};

const App: React.FC<AppProps> = ({className}) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="header">
                    <div className="top-bar">
                        <div className="background"></div>
                        <div className="logo"></div>
                    </div>
                    <div className="padding">
                        <div className="splash">
                            <h1>Bringing Eugene <span>fast and affordable</span> internet</h1>
                        </div>
                        <div className="pricing">
                            <div className="price">
                                <div className="title">
                                    Fast
                                </div>
                                <div className="details">
                                    <span><strong>100</strong> mbps*</span>
                                    <span><strong>$45</strong> per month</span>
                                </div>
                            </div>
                            <div className="price">
                                <div className="title">
                                    Faster
                                </div>
                                <div className="details">
                                    <span><strong>1,000</strong> mbps*</span>
                                    <span><strong>$75</strong> per month</span>
                                </div>
                            </div>
                            <div className="price">
                                <div className="title">
                                    Business
                                </div>
                                <div className="details">
                                    <span>up to <strong>10Gbps</strong>*</span>
                                    <span>(to be determined)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sign-up">
                        <a className="button" href="https://forms.gle/KMZrsSZN6FBddZpY8" target="_blank">Sign up for
                            updates</a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="info padding">
                    <p className="note">
                        * all speeds are symmetric meaning you'll be able to upload and download
                        up to the listed rate. No data caps. 10G network ports available for a one time fee,
                        this provides line rate communication to our other customers.
                    </p>
                    <p>
                        <h2>Building a new network</h2>
                        We’re building a <strong>Fiber To The Home (FTTH)</strong> network in Eugene Oregon.
                        With this network you will be able to access some of the fastest internet speeds
                        in the country. We're currently in the planning phase trying to determine where our first
                        customers will be located. If you are in the Eugene area and want fast internet
                        access, <a href="https://forms.gle/KMZrsSZN6FBddZpY8" target="_blank">please let us know</a>.
                    </p>
                    <p>
                        <h2>We make it simple!</h2>
                        <ul>
                            <li>No hidden fees</li>
                            <li>No contract, cancel at any time</li>
                            <li>No bandwidth caps</li>
                            <li>No teaser or promotion rates that expire</li>
                        </ul>
                    </p>
                    <p>
                        <h2>Project Phases</h2>
                        <ul className="list">
                            <li className="current">Planning</li>
                            <li>Permitting</li>
                            <li>Construction</li>
                            <li>Connecting</li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    );
};

const dark_green = '#323B31';
const green = '#62BE5A';
const light_gray = '#ACACAC';

export default styled(App)`
  .container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .padding {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .sign-up {
    width: 300px;
    max-width: 80%;
    background: white;
    padding: 20px;
    border: solid 1px ${light_gray};
    height: 50px;
    margin: 0 auto -50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .button {
      padding: 10px 15px;
      background: ${green};
      color: white;
      box-shadow: 3px 3px 0 #444444;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        box-shadow: 3px 3px 0 black;
        background: ${dark_green};
      }
    }
  }

  .header {
    margin-bottom: 80px;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top-bar {
      .background {
        background: ${dark_green};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 50%;
      }

      .logo {
        z-index: 10;
        background: url("${img_logo}");
        background-size: cover;
        width: 194px;
        height: 55px;
      }

      position: relative;
      padding: 20px 0;
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    background-color: #e1e5f8;
    background-image: url("${img_background}");
    background-position: center;
    background-size: cover;
  }

  .splash {
    padding: 100px 0;

    h1 {
      font-size: 24px;
      margin: 10px 0;
      line-height: 45px;
      font-weight: bold;
      color: black;
    }

    span {
      padding: 0 15px;
      display: inline-block;

      color: white;
      background: ${green};
      box-shadow: 3px 3px 0 black;
    }
  }

  .pricing {
    display: flex;
    flex-direction: row;
    padding-bottom: 80px;
    flex-wrap: wrap;
    margin: 0 -10px;

    .price {
      display: flex;
      flex-direction: column;
      background: white;
      box-shadow: 3px 3px 0 black;
      margin: 10px;
      flex-grow: 1;

      .title {
        background: ${green};
        font-weight: bold;
        font-size: 24px;
        color: white;
        width: 100%;
        text-align: center;
        padding: 5px 0;
      }

      .details {
        font-size: 18px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        text-align: center;
        line-height: 30px;
      }
    }
  }

  //.info {
  //  margin: 0 -10px;
  //}

  .note {
    font-size: 12px;
    padding: 10px;
    background: ${green};
    width: 100%;
  }

  p {
    line-height: 170%;
    float: left;
    box-sizing: border-box;
    min-width: 50%;
  }

  a {
    cursor: pointer;
    color: ${dark_green};
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    &.list {
      list-style: decimal;
    }

    li {
      padding: 0 5px;

      &.current {
        background: #beeebe;

        &::after {
          content: "current";
          color: #6ca46c;
          float: right;
        }
      }
    }
  }
`;
